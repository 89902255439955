<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="참석">참석</b-form-radio>
              <b-form-radio value="불참">불참</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '참석' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-radio-group v-model="q.answer[1].data">
              <b-form-radio value="보건소">보건소</b-form-radio>
              <b-form-radio value="병원">병원</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q44',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '당뇨 교육 참석 여부',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '당뇨 교육 참석 여부',
        answer: [
          {
            data: '',
          },
          {
            label: '참석 장소',
            data: '',
          },
        ],
      };
    }
    if (this.q.answer[0].data === '불참') this.q.answer[1].data = ''; // '불참' 선택시 '참석' 하위의 질문 답변 초기화
  },
};
</script>